<template>
  <div>
    <x-header>
      <img
        slot="headerImage"
        src="../../assets/haitielianyun/banner.jpg"
        alt=""
      />
      <!-- <h1 slot="headerTitle">海铁联运—铁路运输及国际海运</h1> -->
      <h1 slot="headerTitle">{{$store.state.navShow == true ? '海铁联运—铁路运输及国际海运' : 'transport - rail transport and international shipping'}}</h1>
    </x-header>
    <!-- 中俄海铁联运 -->
    <div class="haitielianyun">
      <!-- <p>
        MG集团是专业的国际铁路运输服务商，在俄罗斯本土拥有强大的铁路运输资源优势，在海运方面拥有十多年的运营经验，拥有专业化管理团队和精细化运营模式，打造了海铁联运精品线路：通过海运可由天津、上海、宁波等国内20多个城市至海参崴换乘铁路、汽运等运输方式，到俄罗斯境内莫斯科/圣彼得堡/叶卡捷琳堡/明斯克清关，为客户提供多种运输补充方案及高效、便捷安全的服务。
      </p> -->
      <p>
        {{$store.state.navShow == true ? ' MG集团是专业的国际铁路运输服务商，在俄罗斯本土拥有强大的铁路运输资源优势，在海运方面拥有十多年的运营经验，拥有专业化管理团队和精细化运营模式，打造了海铁联运精品线路：通过海运可由天津、上海、宁波等国内20多个城市至海参崴换乘铁路、汽运等运输方式，到俄罗斯境内莫斯科/圣彼得堡/叶卡捷琳堡/明斯克清关，为客户提供多种运输补充方案及高效、便捷安全的服务。' : 'MG Group is a professional international railway transportation service provider. It has strong advantages in railway transportation resources in Russia. It has more than ten years of operational experience in shipping, and has a professional management team and refined operation model. It has created a high-quality sea-rail combined transportation. Route: From more than 20 domestic cities such as Tianjin, Shanghai, Ningbo, etc. to Vladivostok by sea, transfer to railway, automobile and other modes of transportation to Moscow / Saint Petersburg / Yekaterinburg / Minsk for customs clearance in Russia, providing customers with a variety of supplementary transportation solutions and Efficient, convenient and safe service.'}}
      </p>
      <div class="table" data-aos="zoom-in">
        <el-table
          class="seaRail"
          :data="$store.state.navShow == true ? tableData : tableDataE"
          border
          :header-cell-style="headMerge"
          :row-style="rowMerge"
          style="width: 100%"
        >
          <el-table-column prop="startPlace" :label="$store.state.navShow == true ? '出发地':'Departure'" width="290">
            <!-- 相当于td 为了让内容换行和好修饰 -->
            <template slot-scope="scope">
              <!-- <p @mouseover="aaa(scope)">111111111</p> -->
              <!-- v-html为了渲染 br -->
              <p class="tdContent" v-html="scope.row.startPlace"></p>
            </template>
          </el-table-column>

          <el-table-column prop="seaLine" :label="$store.state.navShow == true ? '海运线':'Shipping line'" width="290">
            <template slot-scope="scope">
              <!-- <p @mouseover="aaa(scope)">111111111</p> -->
              <!-- v-html为了渲染 br -->
              <p class="tdContent" v-html="scope.row.seaLine"></p>
            </template>
          </el-table-column>

          <el-table-column prop="trainLine" :label="$store.state.navShow == true ? '铁路线':'Railway'" width="305">
            <template slot-scope="scope">
              <!-- v-html为了渲染 br -->
              <p class="tdContent" v-html="scope.row.trainLine"></p>
            </template>
          </el-table-column>
<!--
          <el-table-column prop="trainLine1" width="305">
            <template slot-scope="scope">
              v-html为了渲染 br
              <p class="tdContent" v-html="scope.row.trainLine1"></p>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
    </div>

    <!-- 海铁联运精品路线 -->
    <div class="jingPin_roadLine">
      <!-- 内容 -->
      <div class="roadLine_main">
        <!-- <p>海铁联运精品线路</p> -->
        <p>{{$store.state.navShow == true ? '海铁联运精品线路' : 'Sea-rail combined transportation boutique line'}}</p>
        <!-- <span
          >天津、青岛、上海、宁波等港口出发到达海参崴（海运）莫斯科清关、港务方面的优势及铁路线路资源——</span
        > -->
           <span
          > {{$store.state.navShow == true ? '天津、青岛、上海、宁波等港口出发到达海参崴（海运）莫斯科清关、港务方面的优势及铁路线路资源——' : 'Departure from Tianjin, Qingdao, Shanghai, Ningbo and other ports to Vladivostok (maritime) Moscow’s advantages in customs clearance, port affairs and railway line resources——'}}</span
        >
      </div>
      <div class="roadLine_img">
        <div class="centerImg">
          <!-- 中间地图 -->
              <img v-if="$store.state.navShow == true" src="../../assets/haitielianyun/mapCenter.png" alt="" />
          <img v-else src="../../assets/haitielianyun/mapCenterEn.png" alt="" />
          <!-- 左边弹框 -->
          <div class="left_tankuang" data-aos="fade-up-right" >
            <div class="left_tankuangRelative">
              <img src="../../assets/haitielianyun/tankuang2.png" alt="" :style="$store.state.navShow == true ? '' : '; height: 280px;'" />
              <div class="word_div" :style="$store.state.navShow == true ? '' : ';     text-align: left;top: 20%;'">
                <img
                v-if="$store.state.navShow == true"
                  class="tanKuang1_word"
                  src="../../assets/haitielianyun/leftText.png"
                  alt=""
                />
                <img
                v-if="$store.state.navShow == false"
                style="    margin-left: 40px;"
                  class="tanKuang1_word"
                  src="../../assets/haitielianyun/leftTextE.png"
                  alt=""
                />
                <p>
                  {{$store.state.navShow == true ? '包含铁路、陆运、清关等多式联运方式，提供专业的站到站、门到门服务。':'Including railway, land transportation, customs clearance and other multimodal transportation methods, providing professional station-to-station and door-to-door services.'}}
                </p>
              </div>
            </div>
          </div>
          <!-- 右边弹框 -->
          <div class="right_tankuang" data-aos="fade-down-left">
            <div class="right_tankuangRelative">
              <img src="../../assets/haitielianyun/tankuang1.png"  :style="$store.state.navShow == true ? '' : '; height: 300px;'" alt="" />
              <div class="right_word">
                <img
                  class="rightSite"
                  src="../../assets/haitielianyun/rightSite.png"
                  alt=""
                />
                <p>{{$store.state.navShow == true ? '莫斯科清关、港务方面的优势及铁路线路资源':"Moscow's advantages in customs clearance, port affairs and railway line resources"}}</p>
                <span
                  >{{$store.state.navShow == true ? '将货物运抵俄罗斯的新西伯利亚、叶卡捷琳堡、莫斯科、明斯克、圣彼得堡及中亚的阿拉木图、塔什干等各大城市':"hip the goods to Russia's Novosibirsk, Yekaterinburg, Moscow, Minsk, St. Petersburg and Central Asia's Almaty, Tashkent and other major cities"}}</span
                >
              </div>
            </div>
          </div>
        </div>

        <img
          class="twoSideImg"
          src="../../assets/haitielianyun/bottomBanner.png"
          alt=""
        />
        <img class="dianImg" src="../../assets/pages_bottom_img.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import xHeader from '../../components/header.vue'
export default {
  components: {
    // 使用/注册引入的组件 components
    xHeader
  },
  data () {
    return {
      // 表格
      tableData: [
        {
          startPlace: '天津 / 上海 / 宁波 <br>等国内20多个城市',
          seaLine: '海参崴（俄罗斯）',
          trainLine: '莫斯科/圣彼得堡/叶卡捷琳堡/明斯克',
          trainLine1: '塔什干<br>（乌兹别克斯坦）'
        }
      ],
      tableDataE: [
        {
          startPlace: 'More than 20 cities in China such as Tianjin/Shanghai/Ningbo',
          seaLine: 'Vladivostok (Russia)',
          trainLine: 'Moscow / St. Petersburg / Yekaterinburg / Minsk',
          trainLine1: '塔什干<br>（乌兹别克斯坦）'
        }
      ]
    }
  },
  methods: {
    rowMerge ({ row, rowIndex }) {
      return {
        backgroundColor: '#eaf0f7!important'
      }
    },

    headMerge ({ row, column, rowIndex, columnIndex }) {
      // headMerge (e) {
      //   var { row, column, rowIndex, columnIndex } = e

      console.log('column => ', column, columnIndex)
      if (columnIndex === 2) {
        this.$nextTick(() => {
          document.querySelector(`.${column.id}`).setAttribute('colspan', 2)
        })
      }
      // if (columnIndex === 3) {
      //   return {
      //     display: 'none'
      //   }
      // }
      return {
        color: '#2E67B1',
        textAlign: 'center',
        background: '#eaf0f7!important'
      }
    }
  }
}
</script>
<style lang="less" scoped>
.haitielianyun {
  width: var(--width1200px);
  margin: 110px auto 55px;
  text-align: left;
  text-indent: 32px;
  font-family: Microsoft YaHei;
  color: #666666;
  & > p {
    line-height: 32px;

  }
  & > .table {
    margin: 60px 0 75px 0;
    .tdContent {
      text-align: center;
      line-height: 25px;
    }
  }
}
// 海铁联运精品路线
.jingPin_roadLine {
  width: 100%;
  // height: 1000px;
  // background: green;
  position: relative;
  margin-top: -5px;
  & .roadLine_main {
    width: var(--width1200px);
    margin: 0 auto;
    text-align: left;
    & > p {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #2e67b1;
      margin-bottom: 12px;
    }
    & > span {
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-bottom: 15px;
      z-index: 9999 !important;
    }
  }
  & .roadLine_img {
    width: 100%;
    height: 825px;
    // background: yellow;
    // 中间地图
    & > .centerImg {
      & > img {
        position: absolute;
        top: 3.3vw;
        left: 12vw;
      }
      // 左边弹框
      .left_tankuang {
        position: absolute;
        bottom: 13.5vw;
        left: 15.1vw;
        // background: purple;
        .left_tankuangRelative {
          // background: yellow;
          position: relative;
          // height: 150px;
          & > .word_div {
            width: 100%;
            height: 80%;
            // background: red;
            position: absolute;
            // top: 3.4vw;
            // left: 0;
            top: 32%;
            left: 0;
            & > p {
              font-size: 14px;
              font-family: Microsoft YaHei;
              color: #666666;
              text-align: left;
              line-height: 28px;
              padding: 0 20px 0 45px;
              box-sizing: border-box;
              // margin-top: -5px;
            }
          }
        }
      }
      .right_tankuang {
        position: absolute;
        top: 4.4vw;
        right: 16.7vw;

        .right_tankuangRelative {
          position: relative;
          // background: red;
          .right_word {
            width: 92%;
            position: absolute;
            top: 13.5%;
            left: 10%;
            //   background: orange;
            text-align: left;
            font-family: Microsoft YaHei;
            & > p {
              font-weight: bold;
              color: #2e67b1;
              margin: 17px 0 10px 0;
            }
            & > span {
              font-size: 14px;
              font-weight: 400;
              color: #666666;
              line-height: 28px;
              display: inline-block;
              padding-right: 42px;
            }
          }
        }
      }
    }
    & > .twoSideImg {
      position: absolute;
      bottom: 3vw;
      left: 0;
      z-index: -1;
    }
    & > .dianImg {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }
}
</style>
<style lang="less">
// .seaRail {
// .el-table thead{
//     font-size: 16px;
//     font-family: Microsoft YaHei;
//     font-weight: bold;
//     color: #2E67B1;
//     background: orange;
// }
// .el-table tr{
//     background: #f8f8f8!important;
// }
// .el-table__header{
//     width: 100%!important;
//     background: orange;
// }
// .el-table--border, .el-table--group {
//     border: none;
// }
// .el-table--border::after, .el-table--group::after, .el-table::before {
//     background: transparent;
// }
// .el-table th.el-table__cell {
//     background: #f8f8f8!important;
// }
// .el-table .el-table__cell{
//     text-align: center!important;;
// }
// .el-table__body{
//     height: 90px;
//     font-size: 16px!important;
//     font-family: Microsoft YaHei!important;
//     font-weight: 400!important;
//     color: #666666!important;
//     width: 100%!important;
//     text-align: center!important;
// }
// .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
//     border-bottom: 3px solid #fff;
// }
// .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed{
//         border-right: 3px solid #fff;
// }
// }
</style>
